import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const InlineFundingNavigationTransition = (props) => {
  const {
    onNewTransferButtonClicked,
    onLetsGoButtonClicked,
    assistenceText,
  } = props;
  return (
    <div className="form-navigation justify-content-between">
      <div className="inline-funding-navigation-container">
        <div className="text-center">
          <p className="assistance-container-ready">{assistenceText}</p>
          <button
            type="button"
            id="newTransfer"
            className="btn btn-lg btn-default back-button do_capitalize"
            onClick={onNewTransferButtonClicked}
          >
            New Transfer
          </button>
          <button
            type="button"
            id="letsGo"
            className="btn btn-lg btn-raised btn-default do_capitalize"
            onClick={onLetsGoButtonClicked}
          >
            Let&#39;s Go
          </button>
        </div>
      </div>
    </div>
  );
};

InlineFundingNavigationTransition.propTypes = {
  onNewTransferButtonClicked: PropTypes.func,
  onLetsGoButtonClicked: PropTypes.func,
  assistenceText: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

export default connect(mapStateToProps)(InlineFundingNavigationTransition);
